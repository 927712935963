import React, {memo, useContext, useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import Layout from '../components/layout';
import Result from '../components/result';
import Steps from '../components/steps';
import BottomSection from '../components/bottom-section';
import {navigate} from 'gatsby';
import moment from 'moment';
import {UserContext} from '../context/user-context';
import {getDataRequest} from '../requests';
import Cookies from 'js-cookie';
import loop from '../img/icons/search-icon.svg';
import icon1 from '../img/icons/icon-1.svg';
import icon2 from '../img/icons/icon-2.svg';
import icon3 from '../img/icons/icon-3.svg';
import icon4 from '../img/icons/icon-4.svg';

import {playIcon, arrowDownIcon} from '../img/svg-icons';
import Upgrade from '../components/upgrade';
import {getShowUpgradeModal, setToken} from '../helpers';
import './styles.scss';

const Home = ({location}) => {
  const {t, i18n} = useTranslation();
  const {user} = useContext(UserContext);

  useEffect(() => {
    if (
      location &&
      location.search &&
      !location.search.includes('subscription_id') &&
      !location.search.includes('ba_token=')
    ) {
      setToken(
        location.search
          .replace('?token=', '')
          .replace('&new_user=true', '')
          .replace('&new_user=false', ''),
      );
      if (location.search.includes('&new_user=true') && window.location.href.includes('candidatechecker.io')) {
        //@ts-ignore
        console.log(window.dataLayer);
        //@ts-ignore
        window.dataLayer = [];
        //@ts-ignore
        window.dataLayer.push({
          event: `accountRegistration-candidatechecker`,
          registrationOrderId: new Date().toISOString(),
        });
        //@ts-ignore
        window.dataLayer.push({
          event: `registration-candidatechecker`,
          ecommerce: {
            registration: {
              actionField: {
                id: `Registration_${new Date().toISOString()}`,
              },
            },
          },
        });
      }
      const redirect_url = localStorage.getItem('redirect_url');
      if (redirect_url) {
        localStorage.removeItem('redirect_url');
        navigate(redirect_url);
        return;
      }
      navigate(i18n.language === 'ar' ? '/ar/' : '/');
    }
    // setToken('nb7ssliot78056rcyrjkr1p');
  }, [location]);

  const rawPathnameValue =
    global &&
    global.window &&
    global.window.location &&
    global.window.location.pathname &&
    global.window.location.pathname &&
    global.window.location.pathname !== '/' &&
    global.window.location.pathname !== '/ar/' &&
    global.window.location.pathname !== '/ar' &&
    global.window.location.pathname.replace('/ar/', '').replace('/', '');

  const pathnameValue = rawPathnameValue && decodeURI(rawPathnameValue);
  const [usernameValue, setUsernameValue] = useState(
    pathnameValue && pathnameValue !== 'undefined' ? pathnameValue : '',
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [result, setResult] = useState(null);
  const [scanDate, setScanDate] = useState(null);

  const getScanDate = () => {
    let scanTime = '';
    if (scanDate) {
      moment.utc(new Date(scanDate).toISOString()).format('MMM DD, YYYY') ===
      moment.utc(new Date().toISOString()).format('MMM DD, YYYY')
        ? (scanTime = moment.utc(new Date(scanDate).toISOString()).fromNow())
        : (scanTime = moment.utc(new Date(scanDate).toISOString()).format('MMM DD, YYYY h:mm:ss A (UTC)'));
    } else {
      scanTime = t('a-few-seconds-ago');
    }
    return scanTime;
  };

  const handleCaptureOnEnter = e => {
    if (e.key === 'Enter') {
      handleGo();
    }
  };

  const handleChange = e => {
    setErrorMessage('');
    setUsernameValue(e.target.value);
  };

  const modalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const sendScanRequest = async (username, status) => {
    if (username && username.trim()) {
      setRequestLoading(true);
      setErrorMessage('');
      const res = await getDataRequest(username, 'recheck');
      if (res && res.error) {
        setRequestLoading(false);
        res.error === 'Your request limit reached' ? setModalOpen(true) : setErrorMessage(t('error-message'));
        return;
      }
      if (res && res.status === 200 && res.data && (res.data.data || Array.isArray(res.data))) {
        setResult(Array.isArray(res.data) ? res.data : JSON.parse(res.data.data));
        status === 'recheck' || !res.data.scan_time ? setScanDate(null) : setScanDate(null);
        setRequestLoading(false);
      } else if (res && res.data && res.data.error) {
        setRequestLoading(false);
        res.data.error === 'Your request limit reached' ? setModalOpen(true) : setErrorMessage(t('error-message'));
      }
      setRequestLoading(false);
    } else {
      setErrorMessage(t('please-enter-valid-nickname-or-username'));
    }
  };

  const handleNewScanRequest = () => {
    const navLink = usernameValue.trim();
    //const navLinkArr = trimedValue.includes('@') ? trimedValue.match(/^([^@]*)@/) : null;
    //const navLink = navLinkArr ? navLinkArr[1] : trimedValue;
    setResult(null);
    setScanDate(null);
    if ((navLink && navLink.includes(' ')) || !navLink) {
      setErrorMessage(t('please-enter-valid-nickname-or-username'));
      return;
    } else {
      i18n.language === 'ar'
        ? navigate(`/ar/${navLink.toLocaleLowerCase()}`)
        : navigate(`/${navLink.toLocaleLowerCase()}`);
    }
  };

  const handleGo = () => {
    if (pathnameValue === usernameValue) {
      sendScanRequest(pathnameValue, 'check');
    } else {
      handleNewScanRequest();
    }
  };

  useEffect(() => {
    if (pathnameValue && pathnameValue.includes('index.html')) {
      navigate(`/${pathnameValue.replace('index.html', '')}`);
      return;
    }
    if (pathnameValue && pathnameValue !== undefined) {
      const hash = global && global.window && global.window.location && global.window.location.hash;
      setUsernameValue(pathnameValue);
      sendScanRequest(pathnameValue, 'recheck');
    }
  }, [pathnameValue]);

  return (
    <>
      <Layout
        title="Candidate Checker | Find Anyone's Profile"
        metaName={'description'}
        metaContent={pathnameValue && pathnameValue !== 'undefined' ? '' : t('home-page-description')}
      >
        <div className="search-wrapper position-relative">
          <img
            src={icon4}
            style={{width: '45px', height: 'auto', position: 'absolute', top: '25%', left: '6%'}}
            className="d-none d-md-block"
          />
          <img
            src={icon1}
            style={{width: '25px', height: 'auto', position: 'absolute', top: '0%', left: '12%'}}
            className="d-none d-md-block"
          />
          <img
            src={icon1}
            style={{width: '25px', height: 'auto', position: 'absolute', top: '-25%', left: '35%'}}
            className="d-none d-md-block"
          />
          <img
            src={icon3}
            style={{width: '70px', height: 'auto', position: 'absolute', top: '-20%', left: '18%'}}
            className="d-none d-md-block"
          />
          <img src={icon3} style={{width: '45px', height: 'auto', position: 'absolute', top: '-15%', left: '5%'}} />
          <img
            src={icon3}
            style={{width: '70px', height: 'auto', position: 'absolute', top: '15%', left: '18%'}}
            className="d-none d-smdblock"
          />

          <img src={icon1} style={{width: '35px', height: 'auto', position: 'absolute', top: '-20%', left: '90%'}} />
          <img src={icon2} style={{width: '35px', height: 'auto', position: 'absolute', top: '-30%', left: '65%'}} />
          <img
            src={icon2}
            style={{width: '40px', height: 'auto', position: 'absolute', top: '30%', left: '75%'}}
            className="d-none d-md-block"
          />
          <img
            src={icon4}
            style={{width: '45px', height: 'auto', position: 'absolute', top: '25%', left: '88%'}}
            className="d-none d-md-block"
          />
          <img src={icon3} style={{width: '70px', height: 'auto', position: 'absolute', top: '5%', left: '80%'}} />

          <h1 className="page-title">{t('page-title')}</h1>
          <h2 className="page-subtitle mb-4">
            {t('description-1')}
            <br />
            {t('description-2')}
          </h2>
          <div className={`form-container field is-grouped ${i18n.language === 'ar' ? 'left-align' : ''}`}>
            <input
              className={`input is-rounded inputBar ${i18n.language === 'ar' ? 'typing-rl' : ''}`}
              type="text"
              placeholder={t('nickname-or-username')}
              value={usernameValue}
              onChange={handleChange}
              onKeyPress={handleCaptureOnEnter}
              maxLength={320}
            />
            <img
              src={loop}
              style={{
                width: '25px',
                height: '25px',
                position: 'absolute',
                top: '15px',
                left: i18n.language === 'ar' ? '' : '15px',
                right: i18n.language === 'ar' ? '15px' : '',
              }}
            />
            <div className={`buttons is-block btn_capture`}>
              <button
                className={`button is-primary is-outlined is-medium is-rounded inputBarButton ${
                  requestLoading ? 'disabled' : ''
                }`}
                onClick={handleGo}
              >
                {playIcon}
              </button>
            </div>
          </div>
        </div>
        {requestLoading && (
          <div className="control" style={{paddingTop: '10px'}}>
            <div className="is-flex" style={{justifyContent: 'center', textAlign: 'center'}}>
              <progress className="progress is-small is-primary" max="100" style={{maxWidth: '600px'}}>
                5%
              </progress>
            </div>
          </div>
        )}

        {errorMessage && (
          <div
            className="is-flex"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              paddingTop: '20px',
            }}
          >
            <article className="message is-danger" style={{maxWidth: '300px'}}>
              <div className="message-body">{errorMessage}</div>
            </article>
          </div>
        )}
        {result && (
          <Result
            result={result}
            name={pathnameValue}
            headerCenter={
              <div className="scan-info">
                {
                  <div className="py-1 time-scraped">
                    {t('scraped')} {getScanDate()}
                    {pathnameValue && pathnameValue !== 'undefined' && (
                      <div
                        onClick={() => sendScanRequest(pathnameValue, 'recheck')}
                        className={`rescan-btn ${requestLoading ? 'disabled' : ''}`}
                      >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            className="svg-icon"
                            d="M13.8064 6.27219C13.6639 6.25647 13.0783 6.19183 12.997 6.18283C12.6022 3.0042 9.89065 0.476273 6.52373 0.476273C2.9181 0.476273 0 3.39404 0 7C0 10.6057 2.91777 13.5237 6.52373 13.5237C8.41816 13.5237 10.1314 12.7163 11.3298 11.4114C11.543 11.1793 11.5276 10.8183 11.2955 10.6051C11.0634 10.3919 10.7023 10.4073 10.4892 10.6394C9.50504 11.711 8.09452 12.3824 6.52373 12.3824C3.55586 12.3824 1.14133 9.96786 1.14133 7C1.14133 4.03214 3.55586 1.6176 6.52373 1.6176C9.16861 1.6176 11.374 3.53524 11.8226 6.05319C11.6639 6.03567 11.8205 6.05295 10.9756 5.95968C10.8089 5.94128 10.6852 6.1107 10.7534 6.26388L11.9015 8.84163C11.9691 8.99339 12.1751 9.01614 12.2742 8.88278L13.9569 6.61751C14.0569 6.48293 13.9731 6.29059 13.8064 6.27219Z"
                            fill="#BBC2C5"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                }
              </div>
            }
          />
        )}
        {!result && <Steps />}
        {/* {(user && user.package_data && user.package_data.name && user.package_data.name.toLowerCase() === 'free') ||
        (user && !user.package_data) ? (
          <>
            <a href="https://ad.candidatechecker.io" target="_top" id="512104">
              <img src="//a.impactradius-go.com/display-ad/7452-512104" border="0" alt="" width="728" height="90" />
            </a>
            <img
              height="0"
              width="0"
              src="https://nordvpn.sjv.io/i/2559811/512104/7452"
              style={{position: 'absolute', visibility: 'hidden'}}
              border="0"
            />
          </>
        ) : null} */}
        {!result && <BottomSection />}
      </Layout>
      {modalOpen && (
        <Upgrade openModal={modalOpen} setOpenModal={modalToggle} upgrade={user && user.id} limit_reached />
      )}
    </>
  );
};
export default memo(Home);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
