import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import img1 from '../../img/home-illustration-1.svg';
import img2 from '../../img/home-illustration-2.svg';
import './styles.scss';

export default ({title1, title2, text1, text2}) => {
  const {t, i18n} = useTranslation();

  return (
    <div className="home-bottom-container">
      <div className="row">
        <div className="col-12 col-lg-6 text-section">
          <h2 className={i18n.language === 'ar' ? 'text-right' : ''}>{title1 || `${t('bottom-section-title')}`}</h2>
          <img src={img1} className="d-lg-none py-4" />
          {text1 || (
            <>
              <p className={i18n.language === 'ar' ? 'text-right' : ''}>{t('section-1')}</p>
              <p className={i18n.language === 'ar' ? 'text-right' : ''}>{t('section-2')}</p>
            </>
          )}
        </div>
        <div className="col-12 col-lg-6 d-none d-lg-flex img-section">
          <img src={img1} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 d-none d-lg-flex img-section">
          <img src={img2} />
        </div>
        <div className="col-12 col-lg-6 text-section">
          <h2 className={i18n.language === 'ar' ? 'text-right' : ''}>{title2 || `${t('bottom-section-title-2')}`}</h2>
          <img src={img2} className="d-lg-none py-4" />
          {text2 || (
            <p className={i18n.language === 'ar' ? 'text-right' : ''}>
              {t('section-3')}
              <span style={{color: '#fff', fontWeight: 700}}>{t('400')}</span> {t('section-4')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
