import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import {step1, step2, step3} from '../../img/svg-icons';
import './styles.scss';

export default ({text1, text2, text3}) => {
  const {t, i18n} = useTranslation();
  return (
    <div className="setps-container">
      <div className="steps-list">
        <div className="step">
          <span className="point">{step1}</span>
          <p>{text1 || `${t('Step-1')}`}</p>
        </div>
        <div className="step">
          <span className="point">{step2}</span>
          <p>{text2 || `${t('Step-2')}`}</p>
        </div>
        <div className="step">
          <span className="point">{step3}</span>
          <p>
            {text3 || (
              <>
                {t('Step-3')} <span style={{color: '#fff', fontWeight: 700}}> {t('400')} </span>
                {t('Step-4')}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
